.modal{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  text-align: center;
  padding: 40px 0;
  z-index: 10;
  transition: var(--transition-base);

  &::before{
      display: inline-block;
      vertical-align: middle;
      content: "";
      height: 100%;
      width: 1px;
  }

  opacity: 0;
  visibility: hidden;

  &.active{
      opacity: 1;
      visibility: visible;
  }
}

#buy {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  *{text-align: center;}
  .modal__content {
      @media (max-width: 1798px) {
          background-image:none;
          background:none;
          height: auto;
          background-size: cover; /* Фон покрывает всю доступную область */
          background-repeat: no-repeat; /* Отключаем повторение фона */
          width: 100%;
      }
      background-image:url('../img/back.png');
      width: 1920px;
      height: 1075px;
      background-color: #25564E;
      display: flex;
     
      .close {
          position: absolute;
          color: #fff;
          font-size: 28px;
          text-decoration: none;
          margin: 20px;
          @media (max-width: 520px) {
              font-size: 12px;
              margin: 5px;

          }

      }
      @media (min-width: 1798px) {
          .close {
              left: 15px  
          }            .fl 
          img,
          { display: none;} 
       }
      @media (max-width: 1798px) {
          flex-direction: column;

          .fx {
              margin-top: 790px;
              display: none;
              
          }            
          .overlap-group5 {
              margin: 40px 70px;
              padding: 18px 0;
          }
          .text-5 {
              font-size: 35px;
              line-height:40px;
          }
          .montserrat-normal-white-70px {
              margin-top: 50px;   
              font-size: 28px;
          }
          .span1 {font-size: 28px;}
      }


      @media (min-width: 1798px) {
         
      .fl {
          flex: 0 0 40%; /* Устанавливаем жесткую ширину в 40% */
          
        }
        
        .fx {
          flex: 0 0 60%; /* Устанавливаем жесткую ширину в 60% */
        }
      }
  }

  .span1 {
      color: var(--white);
      font-family: var(--font-family-montserrat);
      font-size: var(--font-size-xxxxxl);
      font-weight: 800;
  }

 .taroirena {
  @media (max-width: 375px) {
      line-height: 1;
  }

      color: var(--white);
      font-family: var(--font-family-montserrat);
      font-size: 78px;
      font-weight: 700;
      letter-spacing: 3px;
      line-height: 95.7px;
      text-align: center;
      margin-top: 50px;
      @media (max-width: 375px) {
         font-size: 18px;
          
      }
    }

       .text-5 {
          color: #5ec8b7;
          font-family: var(--font-family-montserrat);
          font-size: 46px;
          font-weight: 400;
          line-height: 56.4px;
          margin-top: 30px;
          padding: 0 80px;
      }
      
      .overlap-group5 {
          align-items: flex-start;
          background-color: var(--white);
          border-radius: 224.8px;
          box-shadow: 0px 0px 44.96px #ffffff;
          display: flex;
          height: 95px;
          padding: 18.8px 230.0px;  
          text-decoration: none;  
          margin: 120px 70px;
          }

          .text-6{    color: #054138;
              font-family: var(--font-family-montserrat);
              font-size: 44px;
              font-weight: 500;
              line-height: normal;
              min-height: 54px;
              width: 462px;
              margin: 0 auto;
              text-align:center}

}



:root { 
  --abbey: #4d4d4d;
  --black: #000000;
  --blue-marguerite: #7663d4;
  --cloud: #c5c5c5;
  --fuchsia-blue: #6e5dc1;
  --gulf-stream: #7eb6ad;
  --lima: #83bb0d;
  --orange: #ff5e1e;
  --plum: #8d468e;
  --portage: #9986f7;
  --qyhzd4: #ffede6;
  --regal-blue: #13416d;
  --white: #ffffff;
  --white-2: #ffffff1a;
  --white-3: #ffffff33;
 
  --font-size-l: 13px;
  --font-size-m: 12px;
  --font-size-xl: 14px;
  --font-size-xxl: 16px;
  --font-size-xxxl: 18px;
  --font-size-xxxxl: 22px;
  --font-size-xxxxxl: 70px;
 
  --font-family-eb_garamond-medium: "EB Garamond-Medium", Helvetica;
  --font-family-inter-bold: "Inter-Bold", Helvetica;
  --font-family-inter-medium: "Inter-Medium", Helvetica;
  --font-family-inter-regular: "Inter-Regular", Helvetica;
  --font-family-montserrat: "Montserrat", Helvetica;
  --font-family-montserrat-bolditalic: "Montserrat-BoldItalic", Helvetica;
  --font-family-montserrat-mediumitalic: "Montserrat-MediumItalic", Helvetica;
  --font-family-montserrat-semibold: "Montserrat-SemiBold", Helvetica;
  --font-family-muller-bold: "Muller-Bold", Helvetica;
  --font-family-muller-extrabold: "Muller-ExtraBold", Helvetica;
  --font-family-muller-light: "Muller-Light", Helvetica;
  --font-family-muller-regular: "Muller-Regular", Helvetica;
  --font-family-tahoma-bold: "Tahoma-Bold", Helvetica;
}
.montserrat-normal-regal-blue-12px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-bold-lima-16px {
  color: var(--lima);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-regal-blue-12px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.montserrat-medium-regal-blue-12px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.montserrat-bold-regal-blue-16px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-extra-bold-regal-blue-22px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 800;
}

.montserrat-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-montserrat-semibold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-white-12px {
  color: var(--white);
  font-family: var(--font-family-montserrat-semibold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.montserrat-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-orange-16px {
  color: var(--orange);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.muller-bold-regal-blue-18px {
  color: var(--regal-blue);
  font-family: var(--font-family-muller-bold);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.muller-light-regal-blue-14px {
  color: var(--regal-blue);
  font-family: var(--font-family-muller-light);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 300;
}

.montserrat-medium-regal-blue-16px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}

.montserrat-extra-bold-orange-12px {
  color: var(--orange);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 800;
}

.montserrat-extra-bold-regal-blue-12px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 800;
}

.montserrat-medium-portage-60px {
  color: var(--portage);
  font-family: var(--font-family-montserrat);
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-regal-blue-16px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat-semibold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.montserrat-normal-regal-blue-14px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.montserrat-normal-white-70px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxxl);
  font-style: normal;
  font-weight: 400;
  margin-top: 125px;
  margin-left: 1em;
  text-transform: uppercase;

  
}

.montserrat-bold-regal-blue-14px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.montserrat-medium-portage-29px {
  color: var(--portage);
  font-family: var(--font-family-montserrat);
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
}

.ebgaramond-medium-regal-blue-21px {
  color: var(--regal-blue);
  font-family: var(--font-family-eb_garamond-medium);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-regal-blue-28-1px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: 28.1px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-extra-bold-white-22px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 800;
}

.montserrat-medium-regal-blue-20px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-bold-white-34px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-semi-bold-regal-blue-22px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat-semibold);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 600;
}

.montserrat-extra-bold-orange-14px {
  color: var(--orange);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 800;
}

.montserrat-extra-bold-regal-blue-16px {
  color: var(--regal-blue);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 800;
}

.montserrat-medium-white-28-1px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: 28.1px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gulf-stream-28px {
  color: var(--gulf-stream);
  font-family: var(--font-family-montserrat);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gulf-stream-29px {
  color: var(--gulf-stream);
  font-family: var(--font-family-montserrat);
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-orange-29px {
  color: var(--orange);
  font-family: var(--font-family-montserrat);
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-black-38px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
}

.inter-medium-black-16px {
  color: var(--black);
  font-family: var(--font-family-inter-medium);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}





.modal__content{
  @media (max-width: 768px) {
      border-radius: 30px;
  }
  @media (max-width: 486px) {
      .input {
          grid-template-columns:  minmax(0, 1fr);
      }
  }
  background: var(--color-bg-nude-300);
  border-radius: 60px;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  max-width: calc(100% - 20px);
  width: 622px;

  &_big{
      width: 740px;

      padding: 60px 20px;


      @media (min-width: 1200px) {
          width: 1296px;
          padding: 60px 216px;
      }
  }
}

.modal__backing{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
}


.modal__form{
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 10px;
  text-align: left;

  .textarea{
      margin: 32px 0;
  }


  .modal-pay &{
      gap: 0;
  }
}

.modal__form-button{
  display: grid;
  grid-template-columns: minmax(0, 1fr);

}

.modal__title{
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 95%;
  margin-bottom: 40px;
  text-align: left;
  padding-right: 40px;

  .modal__content_big &{
      text-align: center;
      padding: 0 40px;
  }
}


.modal__close{
  position: absolute;
  width: 35px;
  height: 35px;
  right: 40px;
  top: 40px;
  background: none;
  border: none;
  transition: var(--transition-base);
  cursor: pointer;
  outline: none;

  &::before{
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='35' viewBox='0 0 34 35' fill='none'%3E%3Cline x1='8.51555' y1='25.9704' x2='25.4861' y2='8.99982' stroke='%23474356' stroke-width='2'/%3E%3Cline x1='25.4843' y1='25.9704' x2='8.51374' y2='8.99985' stroke='%23474356' stroke-width='2'/%3E%3C/svg%3E");
  }

  &:hover{
      transform: scale(1.1);
  }
}




.pay-result{
  display: grid;
  background: var(--color-bg-nude-100);
  padding: 15px 10px;
  grid-template-columns: minmax(0, 1fr)  minmax(0, max-content);
  gap: 10px;
  border-radius: 8px;
  align-items: center;

  margin-top: 20px;

  @media (min-width: 768px) {
      border-radius: 20px;
      padding: 10px 20px;
      margin-top: 40px;
  }

  @media (min-width: 1200px) {
      gap: 24px;
  }

}

.pay-result__payments{
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 4px;

  @media (min-width: 768px) {
      gap: 8px;
  }


}

.pay-result__payments-item{
  display: grid;
  grid-template-columns: minmax(0, 16px) minmax(0, 1fr);
  gap: 4px;
  align-items: center;

  &_accent{
      .pay-result__payments-item-title{
          color: var(--color-secondary-100);
      }

      .pay-result__payments-item-icon{
          svg{
              stroke: var(--color-secondary-100);
          }
      }
  }
  
  @media (min-width: 768px) {
      grid-template-columns: minmax(0, 24px) minmax(0, 1fr);
  }
}

.pay-result__payments-item-title{
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;


  @media (min-width: 768px) {
      font-size: 14px;
  }
}

.pay-result__payments-item-icon{
  width: 16px;
  height: 16px;

  @media (min-width: 768px) {
      width: 16px;
      height: 16px;
  }
}



.pay-result__price-title{
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; 
  margin-bottom: 4px;

  @media (min-width: 768px) {
      font-size: 14px;
  }
}

.pay-result__price-value{
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 80%;


  @media (min-width: 768px) {
      font-size: 50px;
  }
}




.modal-pay__title{
  text-align: center;
  margin-top: 20px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media (min-width: 768px) {
      margin-top: 40px;
      font-size: 20px;
  }
}


.modal-pay__grid{
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 20px;
  margin-top: 20px;

  @media (min-width: 768px) {
      margin-top: 40px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}


.modal-pay__card{
  border-radius: 30px;
  background: var(--color-bg-white);
  padding: 40px;
}


.modal-pay__card-title{
  // color: var(--orange, #FF7B1C);

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 95%;
  margin-bottom: 8px;

  span{
      color: #FF7B1C;
  }

  &:not(:first-child){
      margin-top: 60px;
  }
}

.modal-pay__card-desc{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}


.modal-pay__card-button{
  margin-top: 24px;
}



.modal-pay__credit-form-button{
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  margin-top: 20px;
}


.modal-pay__services{
  margin-top: 30px;

  @media (min-width: 768px) {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
  }
}

.modal-pay__services-items{
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  @media (min-width: 768px) {
      margin: 0;
  }
}

.modal-pay__services-title{
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}



.slide-fix {
  .modal__close {
      width: 35px;
      right: 10px;
      top: 10px;
  }
 // opacity: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  transition: all 1s;
  padding: 16px;
  position: relative;
  background-color: var(--color-button-bg_disabled);
  border-radius: 45px 45px 8px 45px;
  overflow: hidden;
  right: 90px;
  bottom: 205px;
  position: fixed;
    @media (max-width: 768px) {
      right: 65px;
  }
}

.slide-fix .ellipse {
  position: relative;
  width: 90px;
  height: 90px;
  background-color: var(--color-bg-gray-100);
  border-radius: 45px;
}

.slide-fix .frame {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.slide-fix .text-wrapper {
  width: fit-content;
  margin-top: -1px;
  font-family: "Lato-Bold", Helvetica;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  white-space: nowrap;
  position: relative;
  color: var(--variable-collection-text);
  letter-spacing: 0;
}

.slide-fix .div {
  width: 300px;
  text-align: left;
  @media (max-width: 768px) {
     font-size: 12px;
  }
}

.slide-fix .menu-close-SM {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}


.modal__content_video{
  @media (max-width: 768px) {
      border-radius: 30px;
  }
  @media (max-width: 486px) {
      .input {
          grid-template-columns:  minmax(0, 1fr);
      }
  }
  border-radius: 60px;
  padding: 40px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  max-width: calc(100% - 20px);
  width: 90%;
  height: 485px;
  max-width: 800px;
  @media (max-width: 768px) {
      height: 250px;
      padding: 0;
  }
}
